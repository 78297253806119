<template>
  <div class="myTicketActivity">
    <van-tabs
      v-model:active="active"
      color="#0B6CF9"
      title-active-color="#0B6CF9"
      @change="tabsChange"
    >
      <van-tab
        :title="item.title"
        :name="item.name"
        v-for="(item, index) in tabs"
        :key="index"
      ></van-tab>
    </van-tabs>
    <div v-show="ticketList.length === 0" class="nothing">
      <img src="@/assets/images/nothing.png" alt="" />
      <span>暂无数据</span>
    </div>
    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="ticketList.length === 0 ? '' : '已经没有更多了哦'"
      @load="onLoad"
    >
      <div class="ticketList">
        <div
          class="ticketList_item"
          v-for="(item, index) in ticketList"
          :key="index"
        >
          <h3>{{ item.activityName }}</h3>
          <div class="profile_box" @click="toDetails(item)">
            <div class="left">
              <img :src="item.coverUrl" alt="" />
            </div>
            <div class="profile">
              <p>{{ typeText }}日期：{{ item.Date }}</p>
              <p>{{ typeText }}时间：{{ item.Time }}</p>
              <p>联系人：{{ item.name }}</p>
              <p>{{ typeText }}地点：{{ item.roomName }}</p>
              <p>
                <span v-if="[-2, -1].includes(active)"
                  >报名人数：{{ item.totalNum }}人</span
                >
                <span v-else>中签人数：{{ item.totalNum }}人</span>
                <span v-if="item.needPay && [-2, -1].includes(active)"
                  >费用：<span style="color: #fd4d4f"
                    >￥{{ item.price }}</span
                  ></span
                >
                <span v-if="item.needPay && active === 0">
                  {{ item.payStatus ? '实付：' : '待支付：'
                  }}<span style="color: #fd4d4f">￥{{ item.price }}</span>
                </span>
              </p>
            </div>
          </div>
          <div class="btn_box">
            <template v-if="[0, 1].includes(active)">
              <span
                class="status"
                v-if="item.status === 5"
                style="color: #666666"
                >活动已结束</span
              >
              <span
                class="status"
                v-else-if="item.status === 4"
                style="color: #4bd863"
                >活动进行中</span
              >
              <span
                class="status"
                v-else-if="[1, 3].includes(item.status)"
                style="color: #0b6cf9"
                >活动未开始</span
              >
            </template>
            <van-button
              color="#fd4d4f"
              v-if="
                active === -2 || (active === 0 && [1, 3].includes(item.status))
              "
              plain
              @click="toRefund(item)"
              >取消</van-button
            >
            <template v-if="active === 0">
              <van-button
                color="#0B6CF9"
                plain
                v-if="item.needPay && !item.payStatus && item.status === 3"
                @click="toDetails(item)"
                >去支付</van-button
              >
              <van-button
                color="#4bd863"
                plain
                @click="toDetails(item)"
                v-if="
                  ((item.needPay && item.payStatus) || !item.needPay) &&
                  item.status === 3
                "
                >凭证码</van-button
              >
              <van-button color="#0B6CF9" plain @click="toDetails(item)" v-else
                >详情</van-button
              >
            </template>
            <van-button
              color="#0B6CF9"
              plain
              @click="toDetails(item)"
              v-if="[-1, 1, 2, 3].includes(active)"
              >详情</van-button
            >
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import {
  activityList,
  activityCancel,
  exhibitList,
  exhibitCancel,
} from '@/api/MyTicket';
export default {
  name: 'MyTicketActivity',
  data() {
    return {
      type: '',
      typeText: '',
      active: -2,
      loading: false,
      finished: false,
      ticketList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      getListApi: null,
      refundApi: null,
      title: '',
      tabs: [
        { title: '待摇号', name: -2 },
        { title: '未摇中', name: -1 },
        { title: '待参加', name: 0 },
        { title: '已签到', name: 1 },
        { title: '已取消', name: 2 },
        { title: '已违约', name: 3 },
      ],
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type === '活动报名记录') {
      this.typeText = '活动';
      this.title = '入馆凭证码';
      this.getListApi = activityList;
      this.refundApi = activityCancel;
    } else if (this.type === '临展报名记录') {
      this.typeText = '临展';
      this.title = '入馆凭证码';
      this.getListApi = exhibitList;
      this.refundApi = exhibitCancel;
    }
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.typeText === '活动') {
        data.activityStatus = this.active;
      } else {
        data.exhibitStatus = this.active;
      }
      this.getListApi({ ...data })
        .then((res) => {
          if (res.rows.length > 0) {
            res.rows.forEach((item) => {
              if (this.type === '活动报名记录') {
                let {
                  activityDate,
                  activityStartTime,
                  activityEndTime,
                  coverUrl,
                  activityAddress,
                } = item;
                item.coverUrl = coverUrl;
                item.Date = activityDate;
                item.roomName = activityAddress;
                if (activityStartTime && activityEndTime) {
                  item.Time = activityStartTime + '-' + activityEndTime;
                }
              } else if (this.type === '临展报名记录') {
                let {
                  exhibitDate,
                  exhibitStartTime,
                  exhibitEndTime,
                  coverUrl,
                  exhibitAddress,
                } = item;
                item.coverUrl = coverUrl;
                item.Date = exhibitDate;
                item.roomName = exhibitAddress;
                if (exhibitStartTime && exhibitEndTime) {
                  item.Time = exhibitStartTime + '-' + exhibitEndTime;
                }
              }
            });
          }
          this.ticketList = this.ticketList.concat(res.rows);
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
          if (this.ticketList.length >= this.total) {
            this.finished = true; // 加载结束
            this.ticketList = this.ticketList.filter(
              (obj, index) =>
                this.ticketList.findIndex((item) => item.id === obj.id) ===
                index
            );
          }
        });
    },
    //tabs切换时
    tabsChange() {
      this.reset();
    },
    onLoad() {
      this.pageNum++;
      this.getList();
    },
    //取消报名
    toRefund(item) {
      this.$dialog
        .confirm({
          message: '是否确定要退此票,此操作不能返回。',
        })
        .then(() => {
          let form = {};
          if (this.typeText === '活动') {
            form.activityOrderId = item.id;
          } else {
            form.exhibitOrderId = item.id;
          }
          this.refundApi({ ...form }).then((res) => {
            if (res.code === 200) {
              this.$notify({
                type: 'success',
                message: res.msg,
              });
              this.reset();
            } else {
              this.$notify({
                type: 'danger',
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    reset() {
      this.total = 0;
      this.pageNum = 1;
      this.loading = true;
      this.finished = false;
      this.ticketList = [];
      this.getList();
    },
    //查看详情
    toDetails(item) {
      let data = {
        id: item.id,
      };
      if (this.type === '活动报名记录') {
        data.type = '活动报名详情';
      } else if (this.type === '临展报名记录') {
        data.type = '临展报名详情';
      }
      this.$router.push({ path: '/myActivityDetails', query: data });
    },
  },
};
</script>
<style lang="scss" scoped>
.myTicketActivity {
  background: #f5f6fa;
  min-height: calc(100vh - 46px);
  .van-tabs {
    margin-bottom: 10px;
  }
  .ticketList {
    .ticketList_item {
      position: relative;
      margin: 0 10px;
      background: #fff;
      border-radius: 5px;
      padding: 10px 10px;
      h3 {
        color: #333;
        font-size: 14px;
        line-height: 24px;
      }
      .profile_box {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        .left {
          height: 120px;
          img {
            min-width: 120px;
            width: 120px;
            height: 120px;
            object-fit: cover;
            // object-fit: contain;
          }
        }
        .profile {
          width: 100%;
          padding: 0 0 0 5px;
          p {
            font-size: 12px;
            color: #666;
            line-height: 22px;
            span + span {
              margin-left: 20px;
            }
          }
        }
      }
      .btn_box {
        border-top: 1px solid #ececec;
        text-align: right;
        .status {
          float: left;
          line-height: 30px;
          font-size: 14px;
          margin-left: 5px;
          margin-top: 6px;
        }
        .van-button {
          width: 70px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          padding: 0;
          margin-left: 10px;
        }
      }
    }
    .ticketList_item + .ticketList_item {
      margin-top: 10px;
    }
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    img {
      width: 60%;
    }
    span {
      font-size: 16px;
      color: #999;
    }
  }
}
</style>
